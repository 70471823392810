@import '../sass/variables';

.default-content {
  max-width: 100%;
  padding: 28px 24px;
  margin-bottom: 108px;

  @media screen and (min-width: 600px) {
    padding: 28px 48px;
  }

  @media screen and (min-width: 700px) {
    padding: 0 48px;
  }

  @media screen and (min-width: 1050px) {
    padding: 0 280px;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .featured-image {
    margin: 0 -24px 12px;
    background-color: #eee;
    padding: 0 24px;
    max-width: calc(100% + 48px);

    @media screen and (min-width: 600px) {
      margin: 0 -48px 12px;
      padding: 0 48px;
      max-width: calc(100% + 96px);
    }

    @media screen and (min-width: 1050px) {
      margin: 0 -280px 12px;
      padding: 0 280px;
      max-width: calc(100% + 560px);
    }
  }

  .project-title {
    font-size: 32px;
  }

  .copy {
    margin-bottom: 40px;
  }

  .callout-text {
    margin: 0 -24px 40px;

    @media screen and (min-width: 600px) {
      margin: 0 -48px 40px;
    }

    @media screen and (min-width: 1050px) {
      margin: 0 -280px 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .projects {
    margin: 0 -24px;

    @media screen and (min-width: 600px) {
      margin: 0 -48px;
    }

    @media screen and (min-width: 1050px) {
      margin: 0 -280px;
    }
  }

  .carousel {
    .slide {
      .legend {
        width: 100%;
        margin: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        text-align: left;
        opacity: 1;
        background-color: $grey--dark;
        color: $white;
        padding: 8px 12px 10px;
      }
    }

    .control-arrow {
      bottom: 39px;
      opacity: 1;

      &:hover {
        background: none;
      }

      &::before {
        transition: border-color 0.21s ease-out;
      }

      &.control-prev:hover::before {
        border-right-color: $grey--light;
      }

      &.control-next:hover::before {
        border-left-color: $grey--light;
      }
    }

    .control-dots {
      position: static;
      padding-left: 0;

      .dot {
        background-color: $grey--light;
        opacity: 1;
        box-shadow: none;

        &.selected {
          background-color: $grey--dark;
        }
      }
    }
  }

  .back-button {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: $black;
    text-decoration: none;
    margin-top: 16px;

    &.active,
    &:hover,
    &:focus {
      color: $grey;
    }
  }
}