@import '../sass/variables';

footer {
  grid-area: footer;
  background-color: $grey;
  padding: 20px 24px;
  color: $white;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-align: center;

  @media screen and (min-width: 480px) {
    height: 64px;
    padding: 0 34px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  .social {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 480px) {
      justify-content: flex-start;
    }
  }

  a {
    display: block;
    margin-left: 8px;

    &:hover,
    &:focus {
      svg * {
        fill: $grey--light;
      }
    }
  }

  svg {
    display: block;
    width: 32px;
    height: 32px;

    * {
      transition: fill 0.21s ease-out;
    }
  }

  .copyright {
    margin-bottom: 16px;
    font-size: 12px;

    @media screen and (min-width: 480px) {
      margin-bottom: 0;
    }
  }
}