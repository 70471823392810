html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.callout-text {
  background-color: #333;
  color: #fff;
  padding: 32px 28px 18px; }
  .callout-text p {
    font-family: "Roboto Slab", serif;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    max-width: none; }
    @media screen and (min-width: 630px) {
      .callout-text p {
        font-size: 32px; } }
    @media screen and (min-width: 860px) {
      .callout-text p {
        font-size: 44px; } }

.captioned-image {
  display: inline-block; }
  .captioned-image img {
    display: block; }
  .captioned-image p {
    background-color: #333;
    color: #fff;
    padding: 8px 12px 10px;
    max-width: none; }

.App {
  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  font-family: Roboto, sans-serif;
  padding-top: 46px; }
  @media screen and (min-width: 480px) {
    .App {
      grid-template-rows: auto 1fr 64px; } }
  @media screen and (min-width: 700px) {
    .App {
      padding-top: 0; } }

.content {
  grid-area: content; }

img {
  max-width: 100%; }

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  max-width: 700px; }

h1 {
  font-family: 'Roboto Slab';
  font-size: 48px;
  font-weight: 300;
  line-height: 1.1; }
  @media screen and (min-width: 600px) {
    h1 {
      font-size: 62px; } }

h2 {
  font-family: 'Roboto Slab';
  font-size: 28px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 14px; }
  @media screen and (min-width: 600px) {
    h2 {
      font-size: 32px; } }

h3 {
  font-family: 'Roboto Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 14px; }
  @media screen and (min-width: 600px) {
    h3 {
      font-size: 24px; } }

h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px; }

h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px; }

h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px; }

p {
  line-height: 1.8; }

a {
  -webkit-transition: color 0.21s ease-out;
  transition: color 0.21s ease-out; }

hr {
  border: none;
  border-top: 4px solid #b2b2b2;
  margin: 32px 0; }

.hero-image img {
  display: block; }

.Home .projects {
  display: none; }
  @media screen and (min-width: 550px) {
    .Home .projects {
      display: block; } }

.Home .portfolio-filters {
  margin: 40px 0; }
  @media screen and (min-width: 550px) {
    .Home .portfolio-filters {
      display: none; } }

.header {
  grid-area: header;
  padding: 0 24px 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative;
  z-index: 2; }
  .header__logo {
    display: none; }
    @media screen and (min-width: 700px) {
      .header__logo {
        display: block;
        width: 230px;
        height: 230px; } }
    .header__logo svg path:first-child {
      -webkit-transition: fill 0.21s ease-out;
      transition: fill 0.21s ease-out; }
    .header__logo:hover svg path:first-child, .header__logo:focus svg path:first-child {
      fill: #666; }
  @media screen and (max-width: 699px) {
    .header__shortcuts {
      display: none; } }
  .header__shortcuts ul {
    list-style: none;
    margin: 0 0 20px 22px;
    padding: 0; }
  .header__shortcuts > a {
    padding-left: 22px; }
  .header__shortcuts li {
    margin-bottom: 6px; }
  .header__shortcuts a {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    text-decoration: none; }
    .header__shortcuts a.active, .header__shortcuts a:hover, .header__shortcuts a:focus {
      color: #666; }

.floating-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: -99999px;
  height: 68px;
  background-color: #fff;
  z-index: 2;
  padding: 19px 0 0 24px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: left 0s 0.3s, -webkit-transform 0.3s ease-out;
  transition: left 0s 0.3s, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, left 0s 0.3s;
  transition: transform 0.3s ease-out, left 0s 0.3s, -webkit-transform 0.3s ease-out; }
  @media screen and (min-width: 700px) {
    .floating-header {
      padding-left: 48px; } }
  .floating-header svg {
    width: 178px;
    height: 35px; }
  @media screen and (max-width: 699px) {
    .floating-header {
      left: 0;
      -webkit-transform: translateY(0);
              transform: translateY(0); } }
  .floating-header.slide-down {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    left: 0;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  @media screen and (max-width: 699px) {
    .floating-header .menu-toggle {
      display: none; } }

.main-menu {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  padding: 82px 0; }
  @media screen and (min-width: 780px) {
    .main-menu {
      padding: 82px 28px; } }
  .main-menu--open {
    opacity: 1;
    pointer-events: auto; }
  .main-menu .header__logo {
    display: none; }
    @media screen and (min-width: 840px) {
      .main-menu .header__logo {
        display: block;
        width: 230px;
        height: 230px;
        position: absolute;
        top: 0;
        left: 24px; } }
    .main-menu .header__logo svg {
      width: 100%;
      height: 100%; }
      .main-menu .header__logo svg path {
        fill: #fff; }
        .main-menu .header__logo svg path:first-child {
          fill: #b2b2b2; }
  .main-menu a {
    color: #000;
    text-decoration: none; }
    .main-menu a.active, .main-menu a:hover, .main-menu a:focus {
      color: #666; }
  .main-menu > ul {
    list-style: none;
    margin: 0 0 21px;
    padding: 0 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    @media screen and (min-width: 500px) {
      .main-menu > ul {
        padding: 0 48px; } }
    @media screen and (min-width: 780px) {
      .main-menu > ul {
        padding: 0; } }
    .main-menu > ul > li {
      text-align: center;
      margin-bottom: 6px;
      width: 340px;
      position: relative;
      padding: 0 58px; }
      @media screen and (min-width: 500px) {
        .main-menu > ul > li {
          padding: 0; } }
      .main-menu > ul > li > a {
        font-family: 'Roboto Slab';
        font-size: 40px;
        font-weight: 300; }
        @media screen and (min-width: 500px) {
          .main-menu > ul > li > a {
            font-size: 62px; } }
        @media screen and (min-width: 780px) {
          .main-menu > ul > li > a {
            display: block; } }
      .main-menu > ul > li span {
        display: block;
        width: 24px;
        height: 24px;
        border-right: 3px solid #000;
        border-bottom: 3px solid #000;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        position: absolute;
        top: 24px;
        right: 24px;
        margin-top: -16px;
        -webkit-transition: top 0.3s ease-out, -webkit-transform 0.3s ease-out;
        transition: top 0.3s ease-out, -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out, top 0.3s ease-out;
        transition: transform 0.3s ease-out, top 0.3s ease-out, -webkit-transform 0.3s ease-out; }
        @media screen and (min-width: 500px) {
          .main-menu > ul > li span {
            top: 36px;
            right: 0;
            left: 100%; } }
        @media screen and (min-width: 780px) {
          .main-menu > ul > li span {
            display: none; } }
        .main-menu > ul > li span.open {
          top: 32px;
          -webkit-transform: rotate(-135deg);
                  transform: rotate(-135deg); }
          @media screen and (min-width: 500px) {
            .main-menu > ul > li span.open {
              top: 44px; } }
  .main-menu .subnav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none; }
    @media screen and (min-width: 780px) {
      .main-menu .subnav {
        width: 160px;
        margin: 0;
        position: absolute !important;
        max-height: none !important;
        -webkit-transition: opacity 0.1s ease-out !important;
        transition: opacity 0.1s ease-out !important;
        bottom: -10px;
        left: 100%;
        opacity: 0;
        pointer-events: none;
        display: block !important; } }
    @media screen and (min-width: 780px) {
      .main-menu .subnav.over {
        pointer-events: auto;
        opacity: 1; } }
    .main-menu .subnav li {
      display: block;
      margin-bottom: 6px; }
      .main-menu .subnav li:first-of-type {
        margin-top: 16px; }
      .main-menu .subnav li:last-of-type {
        margin-bottom: 18px; }
      @media screen and (min-width: 780px) {
        .main-menu .subnav li {
          text-align: left; }
          .main-menu .subnav li:first-of-type .main-menu .subnav li:last-of-type {
            margin: 0; } }
    .main-menu .subnav a {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      text-decoration: none; }
      .main-menu .subnav a.active, .main-menu .subnav a:hover, .main-menu .subnav a:focus {
        color: #666; }
  .main-menu .social {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .main-menu .social a {
      display: block;
      margin: 0 5px; }
      .main-menu .social a:hover svg *, .main-menu .social a:focus svg * {
        fill: #b2b2b2; }
  .main-menu svg {
    display: block;
    width: 40px;
    height: 40px; }
    .main-menu svg * {
      fill: #666;
      -webkit-transition: fill 0.21s ease-out;
      transition: fill 0.21s ease-out; }

.menu-toggle {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  width: 38px;
  height: 28px;
  position: fixed;
  top: 20px;
  right: 24px;
  cursor: pointer; }
  @media screen and (min-width: 700px) {
    .menu-toggle {
      position: absolute; } }
  .menu-toggle:hover span, .menu-toggle:focus span {
    background-color: #666; }
  .menu-toggle span {
    display: block;
    width: 38px;
    height: 4px;
    background-color: #000;
    position: absolute;
    left: 0; }
    .menu-toggle span:nth-child(1) {
      top: 0;
      -webkit-transition: background-color 0.21s ease-out, top 0.21s 0.21s ease-out, -webkit-transform 0.21s ease-out;
      transition: background-color 0.21s ease-out, top 0.21s 0.21s ease-out, -webkit-transform 0.21s ease-out;
      transition: background-color 0.21s ease-out, transform 0.21s ease-out, top 0.21s 0.21s ease-out;
      transition: background-color 0.21s ease-out, transform 0.21s ease-out, top 0.21s 0.21s ease-out, -webkit-transform 0.21s ease-out; }
    .menu-toggle span:nth-child(2) {
      top: 12px;
      -webkit-transition: opacity 0s 0.21s;
      transition: opacity 0s 0.21s; }
    .menu-toggle span:nth-child(3) {
      top: 24px;
      -webkit-transition: background-color 0.21s ease-out, top 0.21s 0.21s ease-out, -webkit-transform 0.21s ease-out;
      transition: background-color 0.21s ease-out, top 0.21s 0.21s ease-out, -webkit-transform 0.21s ease-out;
      transition: background-color 0.21s ease-out, transform 0.21s ease-out, top 0.21s 0.21s ease-out;
      transition: background-color 0.21s ease-out, transform 0.21s ease-out, top 0.21s 0.21s ease-out, -webkit-transform 0.21s ease-out; }
  .menu-toggle.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background-color 0.21s ease-out, top 0.21s ease-out, -webkit-transform 0.21s 0.21s ease-out;
    transition: background-color 0.21s ease-out, top 0.21s ease-out, -webkit-transform 0.21s 0.21s ease-out;
    transition: background-color 0.21s ease-out, transform 0.21s 0.21s ease-out, top 0.21s ease-out;
    transition: background-color 0.21s ease-out, transform 0.21s 0.21s ease-out, top 0.21s ease-out, -webkit-transform 0.21s 0.21s ease-out; }
  .menu-toggle.open span:nth-child(2) {
    top: 12px;
    opacity: 0; }
  .menu-toggle.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background-color 0.21s ease-out, top 0.21s ease-out, -webkit-transform 0.21s 0.21s ease-out;
    transition: background-color 0.21s ease-out, top 0.21s ease-out, -webkit-transform 0.21s 0.21s ease-out;
    transition: background-color 0.21s ease-out, transform 0.21s 0.21s ease-out, top 0.21s ease-out;
    transition: background-color 0.21s ease-out, transform 0.21s 0.21s ease-out, top 0.21s ease-out, -webkit-transform 0.21s 0.21s ease-out; }

.projects {
  padding: 22px 14px;
  min-height: 342px;
  position: relative; }
  .projects__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    margin: 0 auto; }
    @media screen and (min-width: 550px) {
      .projects__wrapper {
        width: 500px; } }
    @media screen and (min-width: 800px) {
      .projects__wrapper {
        width: 750px; } }
    @media screen and (min-width: 1048px) {
      .projects__wrapper {
        width: 1000px; } }
  .projects__load-more {
    width: 100%;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    text-align: center;
    padding: 8px 24px 0; }
    .projects__load-more button {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background: none;
      border: none;
      border-radius: 0;
      padding: 8px;
      font-size: 14px;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      cursor: pointer;
      -webkit-transition: color 0.21s ease-out;
      transition: color 0.21s ease-out; }
      .projects__load-more button:hover, .projects__load-more button:focus {
        color: #666; }

.project {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 10px 20px;
  overflow: hidden;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 550px) {
    .project {
      width: 230px; } }
  @media screen and (min-width: 1024px) {
    .project {
      height: 230px; } }
  .project > div {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .project > div {
        display: block;
        width: 230px;
        height: 230px; } }
  .project__image, .project__info {
    position: relative; }
    @media screen and (min-width: 1024px) {
      .project__image, .project__info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
  @media screen and (min-width: 550px) {
    .project__image {
      width: 230px;
      height: 230px; } }
  .project__image::after {
    content: '';
    display: block;
    padding-top: 100%; }
    @media screen and (min-width: 550px) {
      .project__image::after {
        display: none; } }
  .project__image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media screen and (max-width: 1023px) {
      .project__image img {
        position: absolute;
        top: 0;
        left: 0; } }
  .project__info {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    background-color: #333;
    color: #fff;
    padding: 22px 18px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-transition: opacity 0.3s ease-out, background-color 0.3s ease-out;
    transition: opacity 0.3s ease-out, background-color 0.3s ease-out; }
    @media screen and (min-width: 1024px) {
      .project__info {
        width: 230px;
        height: 230px;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.7); } }
  .project__tags {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px; }
  .project h3 {
    margin: 0;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 300;
    line-height: 1.1; }
  .project:hover .project__info, .project:focus .project__info {
    opacity: 1; }
    @media screen and (max-width: 1023px) {
      .project:hover .project__info, .project:focus .project__info {
        background-color: #666; } }

.portfolio-filters {
  list-style: none;
  padding-left: 24px; }
  @media screen and (min-width: 700px) {
    .portfolio-filters {
      display: none; } }
  .portfolio-filters ul {
    list-style: none;
    margin: 0 0 20px 22px;
    padding: 0; }
  .portfolio-filters li {
    margin-bottom: 6px; }
  .portfolio-filters a {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    text-decoration: none; }
    .portfolio-filters a.active, .portfolio-filters a:hover, .portfolio-filters a:focus {
      color: #666; }

.spinner {
  -webkit-animation: rotator 0.8s linear infinite;
          animation: rotator 0.8s linear infinite;
  position: absolute;
  top: 139px;
  left: calc(50% - 32px);
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  -webkit-transition: opacity 0.21s ease-out;
  transition: opacity 0.21s ease-out; }
  .spinner.hide {
    opacity: 0; }

.path {
  stroke: #666;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash 0.8s ease-in-out infinite, colors 3.2s ease-in-out infinite;
          animation: dash 0.8s ease-in-out infinite, colors 3.2s ease-in-out infinite; }

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

.default-content {
  max-width: 100%;
  padding: 28px 24px;
  margin-bottom: 108px; }
  @media screen and (min-width: 600px) {
    .default-content {
      padding: 28px 48px; } }
  @media screen and (min-width: 700px) {
    .default-content {
      padding: 0 48px; } }
  @media screen and (min-width: 1050px) {
    .default-content {
      padding: 0 280px; } }
  .default-content img {
    display: block;
    max-width: 100%; }
  .default-content .featured-image {
    margin: 0 -24px 12px;
    background-color: #eee;
    padding: 0 24px;
    max-width: calc(100% + 48px); }
    @media screen and (min-width: 600px) {
      .default-content .featured-image {
        margin: 0 -48px 12px;
        padding: 0 48px;
        max-width: calc(100% + 96px); } }
    @media screen and (min-width: 1050px) {
      .default-content .featured-image {
        margin: 0 -280px 12px;
        padding: 0 280px;
        max-width: calc(100% + 560px); } }
  .default-content .project-title {
    font-size: 32px; }
  .default-content .copy {
    margin-bottom: 40px; }
  .default-content .callout-text {
    margin: 0 -24px 40px; }
    @media screen and (min-width: 600px) {
      .default-content .callout-text {
        margin: 0 -48px 40px; } }
    @media screen and (min-width: 1050px) {
      .default-content .callout-text {
        margin: 0 -280px 40px; } }
    .default-content .callout-text:last-child {
      margin-bottom: 0; }
  .default-content .projects {
    margin: 0 -24px; }
    @media screen and (min-width: 600px) {
      .default-content .projects {
        margin: 0 -48px; } }
    @media screen and (min-width: 1050px) {
      .default-content .projects {
        margin: 0 -280px; } }
  .default-content .carousel .slide .legend {
    width: 100%;
    margin: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    text-align: left;
    opacity: 1;
    background-color: #333;
    color: #fff;
    padding: 8px 12px 10px; }
  .default-content .carousel .control-arrow {
    bottom: 39px;
    opacity: 1; }
    .default-content .carousel .control-arrow:hover {
      background: none; }
    .default-content .carousel .control-arrow::before {
      -webkit-transition: border-color 0.21s ease-out;
      transition: border-color 0.21s ease-out; }
    .default-content .carousel .control-arrow.control-prev:hover::before {
      border-right-color: #b2b2b2; }
    .default-content .carousel .control-arrow.control-next:hover::before {
      border-left-color: #b2b2b2; }
  .default-content .carousel .control-dots {
    position: static;
    padding-left: 0; }
    .default-content .carousel .control-dots .dot {
      background-color: #b2b2b2;
      opacity: 1;
      box-shadow: none; }
      .default-content .carousel .control-dots .dot.selected {
        background-color: #333; }
  .default-content .back-button {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    margin-top: 16px; }
    .default-content .back-button.active, .default-content .back-button:hover, .default-content .back-button:focus {
      color: #666; }

footer {
  grid-area: footer;
  background-color: #666;
  padding: 20px 24px;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-align: center; }
  @media screen and (min-width: 480px) {
    footer {
      height: 64px;
      padding: 0 34px 0 24px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      text-align: left; } }
  footer .social {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    @media screen and (min-width: 480px) {
      footer .social {
        -webkit-justify-content: flex-start;
                justify-content: flex-start; } }
  footer a {
    display: block;
    margin-left: 8px; }
    footer a:hover svg *, footer a:focus svg * {
      fill: #b2b2b2; }
  footer svg {
    display: block;
    width: 32px;
    height: 32px; }
    footer svg * {
      -webkit-transition: fill 0.21s ease-out;
      transition: fill 0.21s ease-out; }
  footer .copyright {
    margin-bottom: 16px;
    font-size: 12px; }
    @media screen and (min-width: 480px) {
      footer .copyright {
        margin-bottom: 0; } }

