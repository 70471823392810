.hero-image {
  img {
    display: block;
  }
}

.Home {
  .projects {
    display: none;

    @media screen and (min-width: 550px) {
      display: block;
    }
  }

  .portfolio-filters {
    margin: 40px 0;

    @media screen and (min-width: 550px) {
      display: none;
    }
  }
}