.captioned-image {
  display: inline-block;

  img {
    display: block;
  }

  p {
    background-color: $grey--dark;
    color: $white;
    padding: 8px 12px 10px;
    max-width: none;
  }
}