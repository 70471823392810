@import '../sass/variables';

.projects {
  padding: 22px 14px;
  min-height: 342px;
  position: relative;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 550px) {
      width: 500px;
    }

    @media screen and (min-width: 800px) {
      width: 750px;
    }

    @media screen and (min-width: 1048px) {
      width: 1000px;
    }
  }

  &__load-more {
    width: 100%;
    flex: 0 0 auto;
    text-align: center;
    padding: 8px 24px 0;

    button {
      appearance: none;
      background: none;
      border: none;
      border-radius: 0;
      padding: 8px;
      font-size: 14px;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      cursor: pointer;
      transition: color 0.21s ease-out;

      &:hover,
      &:focus {
        color: $grey;
      }
    }
  }
}

.project {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 10px 20px;
  overflow: hidden;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 550px) {
    width: 230px;
  }

  @media screen and (min-width: 1024px) {
    height: 230px;
  }

  > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
      display: block;
      width: 230px;
      height: 230px;
    }
  }

  &__image,
  &__info {
    position: relative;

    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    @media screen and (min-width: 550px) {
      width: 230px;
      height: 230px;
    }

    &::after {
      content: '';
      display: block;
      padding-top: 100%;

      @media screen and (min-width: 550px) {
        display: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media screen and (max-width: 1023px) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__info {
    flex: 1 1 auto;
    background-color: $grey--dark;
    color: $white;
    padding: 22px 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: opacity 0.3s ease-out,
      background-color 0.3s ease-out;

    @media screen and (min-width: 1024px) {
      width: 230px;
      height: 230px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  &__tags {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  h3 {
    margin: 0;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 300;
    line-height: 1.1;
  }

  &:hover,
  &:focus {
    .project__info {
      opacity: 1;

      @media screen and (max-width: 1023px) {
        background-color: $grey;
      }
    }
  }
}

.portfolio-filters {
  list-style: none;
  padding-left: 24px;

  @media screen and (min-width: 700px) {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0 0 20px 22px;
    padding: 0;
  }

  li {
    margin-bottom: 6px;
  }

  a {
    font-size: 18px;
    font-weight: 700;
    color: $black;
    text-decoration: none;

    &.active,
    &:hover,
    &:focus {
      color: $grey;
    }
  }
}

$offset: 187;
$duration: 0.8s;

.spinner {
  animation: rotator $duration linear infinite;
  position: absolute;
  top: 139px;
  left: calc(50% - 32px);
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.21s ease-out;

  &.hide {
    opacity: 0;
  }
}

.path {
  stroke: $grey;
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
 }