.callout-text {
  background-color: $grey--dark;
  color: $white;
  padding: 32px 28px 18px;

  p {
    font-family: "Roboto Slab", serif;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    max-width: none;

    @media screen and (min-width: 630px) {
      font-size: 32px;
    }

    @media screen and (min-width: 860px) {
      font-size: 44px;
    }
  }
}