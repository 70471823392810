@import './sass/variables';
@import './sass/callout-text';
@import './sass/captioned-image';

.App {
  display: grid;
  grid-template-areas: "header"
                       "content"
                       "footer";
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  font-family: Roboto, sans-serif;
  padding-top: 46px;

  @media screen and (min-width: 480px) {
    grid-template-rows: auto 1fr 64px;
  }

  @media screen and (min-width: 700px) {
    padding-top: 0;
  }
}

.content {
  grid-area: content;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  max-width: 700px;
}

h1 {
  font-family: 'Roboto Slab';
  font-size: 48px;
  font-weight: 300;
  line-height: 1.1;

  @media screen and (min-width: 600px) {
    font-size: 62px;
  }
}

h2 {
  font-family: 'Roboto Slab';
  font-size: 28px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 14px;

  @media screen and (min-width: 600px) {
    font-size: 32px;
  }
}

h3 {
  font-family: 'Roboto Slab';
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 14px;

  @media screen and (min-width: 600px) {
    font-size: 24px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

p {
  line-height: 1.8;
}

a {
  transition: color 0.21s ease-out;
}

hr {
  border: none;
  border-top: 4px solid $grey--light;
  margin: 32px 0;
}