@import '../sass/variables';

.header {
  grid-area: header;
  padding: 0 24px 22px;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 2;

  &__logo {
    display: none;

    @media screen and (min-width: 700px) {
      display: block;
      width: 230px;
      height: 230px;
    }

    svg path:first-child {
      transition: fill 0.21s ease-out;
    }

    &:hover,
    &:focus {
      svg path:first-child {
        fill: $grey;
      }
    }
  }

  &__shortcuts {
    @media screen and (max-width: 699px) {
      display: none;
    }

    ul {
      list-style: none;
      margin: 0 0 20px 22px;
      padding: 0;
    }

    > a {
      padding-left: 22px;
    }

    li {
      margin-bottom: 6px;
    }

    a {
      font-size: 18px;
      font-weight: 700;
      color: $black;
      text-decoration: none;

      &.active,
      &:hover,
      &:focus {
        color: $grey;
      }
    }
  }
}

.floating-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: -99999px;
  height: 68px;
  background-color: $white;
  z-index: 2;
  padding: 19px 0 0 24px;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out,
    left 0s 0.3s;

  @media screen and (min-width: 700px) {
    padding-left: 48px
  }

  svg {
    width: 178px;
    height: 35px
  }

  @media screen and (max-width: 699px) {
    left: 0;
    transform: translateY(0);
  }

  &.slide-down {
    transform: translateY(0);
    left: 0;
    transition: transform 0.3s ease-out;
  }

  .menu-toggle {
    @media screen and (max-width: 699px) {
      display: none;
    }
  }
}

.main-menu {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 82px 0;

  @media screen and (min-width: 780px) {
    padding: 82px 28px;
  }

  &--open {
    opacity: 1;
    pointer-events: auto;
  }

  .header__logo {
    display: none;

    @media screen and (min-width: 840px) {
      display: block;
      width: 230px;
      height: 230px;
      position: absolute;
      top: 0;
      left: 24px;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff;

        &:first-child {
          fill: $grey--light;
        }
      }
    }
  }

  a {
    color: $black;
    text-decoration: none;

    &.active,
    &:hover,
    &:focus {
      color: $grey;
    }
  }

  > ul {
    list-style: none;
    margin: 0 0 21px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 500px) {
      padding: 0 48px;
    }

    @media screen and (min-width: 780px) {
      padding: 0;
    }

    > li {
      text-align: center;
      margin-bottom: 6px;
      width: 340px;
      position: relative;
      padding: 0 58px;

      @media screen and (min-width: 500px) {
        padding: 0;
      }

      > a {
        font-family: 'Roboto Slab';
        font-size: 40px;
        font-weight: 300;

        @media screen and (min-width: 500px) {
          font-size: 62px;
        }

        @media screen and (min-width: 780px) {
          display: block;
        }
      }

      span {
        display: block;
        width: 24px;
        height: 24px;
        border-right: 3px solid $black;
        border-bottom: 3px solid $black;
        transform: rotate(45deg);
        position: absolute;
        top: 24px;
        right: 24px;
        margin-top: -16px;
        transition: transform 0.3s ease-out,
          top 0.3s ease-out;

        @media screen and (min-width: 500px) {
          top: 36px;
          right: 0;
          left: 100%;
        }

        @media screen and (min-width: 780px) {
          display: none;
        }

        &.open {
          top: 32px;
          transform: rotate(-135deg);

          @media screen and (min-width: 500px) {
            top: 44px;
          }
        }
      }
    }
  }

  .subnav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;

    @media screen and (min-width: 780px) {
      width: 160px;
      margin: 0;
      position: absolute !important;
      max-height: none !important;
      transition: opacity 0.1s ease-out !important;
      bottom: -10px;
      left: 100%;
      opacity: 0;
      pointer-events: none;
      display: block !important;
    }

    &.over {
      @media screen and (min-width: 780px) {
        pointer-events: auto;
        opacity: 1;
      }
    }

    li {
      display: block;
      margin-bottom: 6px;

      &:first-of-type {
        margin-top: 16px;
      }

      &:last-of-type {
        margin-bottom: 18px;
      }

      @media screen and (min-width: 780px) {
        text-align: left;

        &:first-of-type
        &:last-of-type {
          margin: 0;
        }
      }
    }

    a {
      font-size: 18px;
      font-weight: 700;
      color: $black;
      text-decoration: none;

      &.active,
      &:hover,
      &:focus {
        color: $grey;
      }
    }
  }

  .social {
    display: flex;
    justify-content: center;

    a {
      display: block;
      margin: 0 5px;

      &:hover,
      &:focus {
        svg * {
          fill: $grey--light;
        }
      }
    }
  }

  svg {
    display: block;
    width: 40px;
    height: 40px;

    * {
      fill: $grey;
      transition: fill 0.21s ease-out;
    }
  }
}

.menu-toggle {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  width: 38px;
  height: 28px;
  position: fixed;
  top: 20px;
  right: 24px;
  cursor: pointer;

  @media screen and (min-width: 700px) {
    position: absolute;
  }

  &:hover,
  &:focus {
    span {
      background-color: $grey;
    }
  }

  span {
    display: block;
    width: 38px;
    height: 4px;
    background-color: $black;
    position: absolute;
    left: 0;

    &:nth-child(1) {
      top: 0;
      transition: background-color 0.21s ease-out,
        transform 0.21s ease-out,
        top 0.21s 0.21s ease-out;
    }

    &:nth-child(2) {
      top: 12px;
      transition: opacity 0s 0.21s;
    }

    &:nth-child(3) {
      top: 24px;
      transition: background-color 0.21s ease-out,
        transform 0.21s ease-out,
        top 0.21s 0.21s ease-out;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 12px;
        transform: rotate(45deg);
        transition: background-color 0.21s ease-out,
          transform 0.21s 0.21s ease-out,
          top 0.21s ease-out;
      }
  
      &:nth-child(2) {
        top: 12px;
        opacity: 0;
      }
  
      &:nth-child(3) {
        top: 12px;
        transform: rotate(-45deg);
        transition: background-color 0.21s ease-out,
          transform 0.21s 0.21s ease-out,
          top 0.21s ease-out;
      }
    }
  }
}